import React, { useEffect } from "react";
import "./style.scss";

const data = {
  ru: {
    titleOne: "Почему стоит",
    titleTwo: "выбрать нас?",
    infoCards: [
      {
        img: "/img/trophy-icon.svg",
        text: "Запустили 50+ проектов в 8 странах. Выигрываем международные конкурсы, занимаем топовые места в рейтингах.",
      },
      {
        img: "/img/it-icon.svg",
        text: "Делаем любые ИТ-проекты: от мобильных приложений до highload-систем. Настраиваем интеграции любой сложности.",
      },
      {
        img: "/img/schedule-icon.svg",
        text: "Работаем по SCRUM, запускаем проекты поэтапно. Используем современные технологии и многоступенчатое тестирование.",
      },
    ],
  },
  en: {
    titleOne: "Why",
    titleTwo: "choose us?",
    infoCards: [
      {
        img: "/img/trophy-icon.svg",
        text: "We have successfully launched 50+ projects across 8 countries and earned top rankings in international competitions.",
      },
      {
        img: "/img/it-icon.svg",
        text: "We handle all types of IT projects, from mobile apps to highload systems, and implement integrations of any complexity.",
      },
      {
        img: "/img/schedule-icon.svg",
        text: "Using the SCRUM framework, we launch projects in phases, leveraging modern technologies and multi-stage testing to ensure quality.",
      },
    ],
  },
};

export const WhyChooseUsSection = ({ lang }) => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-in");
          entry.target.addEventListener("animationend", () => {
            entry.target.classList.remove("fade-in");
          });
        }
      });
    });

    const cards = document.querySelectorAll(".card-item");
    cards.forEach((card) => observer.observe(card));
  }, []);

  return (
    <section id="why-choose-us">
      <div className="container-choose">
        <div className="container title">
          {data[lang].titleOne}{" "}
          <span className="title__bg">{data[lang].titleTwo}</span>
        </div>
        <div className="wrapper-cards">
          {data[lang].infoCards.map((e, i) => (
            <div className="card-item" key={`choose-${i}`}>
              <img src={e.img} alt="icon" />
              <p className="item-text">{e.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
